import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import '../components/global.css'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MEDIA, COLORS } from '../components/utils/styles'

const textBGColor = '#000000b8'

const ScGridWrapper = styled.div`
  width: 100%;
  display: grid;
  padding: 2px 5px 5px 2px;

  ${MEDIA.DESKTOP`
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5px;
    row-gap: 5px;
  `}
  ${MEDIA.TABLET`
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
    row-gap: 5px;
  `}
  ${MEDIA.MOBILE`
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
    row-gap: 5px;
  `}
  
  color: white;
`
const ScBeerItem = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  background-image: url('${(props) => props.image}');
  background-size: cover;
  box-shadow: 3px 3px 4px black;
`
const ScImage = styled(GatsbyImage)`
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const ScBrandWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  line-height: 30px;
  background-color: ${textBGColor};
  padding: 0 10px;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MEDIA.MOBILE`
    height: 40px;
    font-size: 15px;
    line-height: 20px;
    padding: 0 3px;
  `}
`
const ScIndexFlex = styled.div`
  display: flex;
  align-items: center;
`
const ScIndex = styled.div`
  text-align: center;
  background-color: ${COLORS.ORANGE};
  border-radius: 50%;
  font-size: 25px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  color: black;
  margin-right: 5px;
  box-shadow: 2px 2px 5px black;

  ${MEDIA.MOBILE`
    font-size: 16px;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
  `}
`
const ScCountryImage = styled(GatsbyImage)`
  box-shadow: 2px 2px 5px black;
  width: 60px;
  height: 40px;
  ${MEDIA.MOBILE`
    width: 30px;
    height: 20px;
  `}
`
const ScDescriptionWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 180px;
  background-color: ${textBGColor};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${MEDIA.MOBILE`
    height: 100px;
  `}
`
const ScName = styled.div`
  text-transform: uppercase;
  font-size: 26px;
  font-weight: bold;
  text-align: center;

  ${MEDIA.MOBILE`
    margin-top: 5px;
    font-size: 16px;
  `}
`
const ScDetail = styled.div`
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  text-align: center;

  ${MEDIA.TABLET`
    font-size: 15px;
  `}

  ${MEDIA.MOBILE`
    font-size: 8px;
  `}
`
const ScType = styled.div`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;

  ${MEDIA.MOBILE`
    font-size: 10px;
  `}
`
const ScPercent = styled.div`
  background-color: ${textBGColor};
  z-index: 2;
  font-size: 20px;
  font-weight: bold;
  color: white;
  position: absolute;
  right: 5px;
  bottom: 190px;
  padding: 3px;
  border-radius: 5px;

  ${MEDIA.MOBILE`
    font-size: 12px;
    line-height: 16px;
    bottom: 105px;
  `}
`
const ScPriceWrapper = styled.div`
  position: absolute;
  bottom: 1px;
`
const ScPriceFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const ScPriceItem = styled.div`
  width: 130px;
  text-align: center;
  background-color: ${(props) => (props.color ? props.color : COLORS.ORANGE)};
  border-radius: 5px;
  margin: 0 7px 5px;
  box-shadow: 2px 2px 5px black;

  ${MEDIA.MOBILE`
    width: 75px;
    margin: 0 4px 5px;
  `}
`
const ScMl = styled.div`
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  color: black;

  ${MEDIA.MOBILE`
    font-size: 12px;
    line-height: 14px;
  `}
`
const ScPrice = styled.div`
  font-size: 40px;
  line-height: 42px;
  font-weight: bold;
  padding-left: 5px;

  ${MEDIA.MOBILE`
    font-size: 20px;
    line-height: 22px;
  `}
`
const ScBaht = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;

  ${MEDIA.MOBILE`
    font-size: 10px;
  `}
`

const ScSoldout = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000000d9;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 90px;
  font-weight: bold;
  color: red;
`
class Tapping extends React.Component {
  render() {
    const beerTaps = get(this, 'props.data.contentfulTappingList.list')
    const availableList = [
      get(this, 'props.data.contentfulTappingList.tap1'),
      get(this, 'props.data.contentfulTappingList.tap2'),
      get(this, 'props.data.contentfulTappingList.tap3'),
      get(this, 'props.data.contentfulTappingList.tap4'),
      get(this, 'props.data.contentfulTappingList.tap5'),
      get(this, 'props.data.contentfulTappingList.tap6'),
      get(this, 'props.data.contentfulTappingList.tap7'),
      get(this, 'props.data.contentfulTappingList.tap8'),
    ]

    return (
      <ScGridWrapper>
        {beerTaps.slice(0, 8).map((beer, index) => {
          return (
            <ScBeerItem
              key={`beer-item-${beer.name}`}
              image={beer.image.fluid.src}
            >
              <ScPercent>
                ABV {parseFloat(beer.alcoholPercentage).toFixed(1)}%
              </ScPercent>
              {availableList[index] === false && (
                <ScSoldout>Sold Out!</ScSoldout>
              )}
              <ScBrandWrapper
                brandFontSize={
                  beer?.brandFontSize ? beer?.brandFontSize - 11 : null
                }
              >
                <ScIndexFlex>
                  <ScIndex>{index + 1}</ScIndex>
                  {beer.brand}
                </ScIndexFlex>
                {beer?.country && (
                  <ScCountryImage
                    image={beer.country.gatsbyImageData}
                    alt={beer.country.title}
                  />
                )}
              </ScBrandWrapper>
              <ScDescriptionWrapper>
                <ScName>{beer.name}</ScName>
                {beer?.detail && <ScDetail>{beer.detail}</ScDetail>}
                <ScType>[ {beer.type} ]</ScType>
                <ScPriceWrapper>
                  <ScPriceFlex>
                    {(!!beer.price || !!beer.bangwaPrice) && (
                      <ScPriceItem>
                        <ScMl>330 ml.</ScMl>
                        <ScPrice>
                          {beer.bangwaPrice ? beer.bangwaPrice : beer.price}
                          <ScBaht>฿</ScBaht>
                        </ScPrice>
                      </ScPriceItem>
                    )}

                    {(!!beer.pricePint || !!beer.bangwaPricePint) && (
                      <ScPriceItem color={COLORS.PINK}>
                        <ScMl>500 ml.</ScMl>
                        <ScPrice>
                          {beer.bangwaPricePint
                            ? beer.bangwaPricePint
                            : beer.pricePint}
                          <ScBaht>฿</ScBaht>
                        </ScPrice>
                      </ScPriceItem>
                    )}
                  </ScPriceFlex>
                </ScPriceWrapper>
              </ScDescriptionWrapper>

              <ScImage image={beer.image.gatsbyImageData} alt={beer.name} />
            </ScBeerItem>
          )
        })}
      </ScGridWrapper>
    )
  }
}

export default Tapping

export const pageQuery = graphql`
  query BW_TappingQuery {
    contentfulTappingList(contentful_id: { eq: "lRdtaBtoERgbv966Yzh1m" }) {
      list {
        brand
        brandFontSize
        name
        detail
        type
        alcoholPercentage
        price
        pricePint
        bangwaPrice
        bangwaPricePint
        specialPrice
        image {
          title
          gatsbyImageData(placeholder: BLURRED, quality: 100)
          fluid {
            src
          }
        }
        country {
          title
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      tap1
      tap2
      tap3
      tap4
      tap5
      tap6
      tap7
      tap8
    }
  }
`
